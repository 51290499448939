import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { PartSubgroup } from '../interface/part-subgroup';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class SubgroupService {
 private _isFromMatrix = true;


  constructor(
    private http: HttpClient,
    private _dataService: DataService
  ) { }

  public async getAll(groupId?: string): Promise<PartSubgroup[]> {
    const url = `${environment.mkgoURL}/api/v1/subgroup`;
    let header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    if (groupId) {
      const params = new HttpParams().set('group', groupId)
      header['params'] = params;
    }
    const resp: any = await this.http.get<{subgroups: PartSubgroup[]}>(url, header).pipe(first()).toPromise();
    return (resp.subgroups || []).reverse();
  }

  public async getById(subgroupId: string) {
    const url = `${environment.mkgoURL}/api/v1/subgroup/${subgroupId}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.get(url, header).pipe(first()).toPromise();
    return resp as PartSubgroup;
  }

  public async register(subgroup: PartSubgroup): Promise<string> {
    subgroup = this.complyApi(subgroup);
    const url = `${environment.mkgoURL}/api/v1/subgroup`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.post(url, JSON.stringify(subgroup), header).pipe(first()).toPromise()
    return resp.id;
  }

  public async update(subgroup: PartSubgroup): Promise<string> {
    const url = `${environment.mkgoURL}/api/v1/subgroup/${subgroup._id}`;
    const apiSubgroup = this.complyApi(subgroup);
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.put(url, JSON.stringify(apiSubgroup), header).pipe(first()).toPromise();
    return resp.id;
  }

  public async delete(subgroupId) {
    const url = `${environment.mkgoURL}/api/v1/subgroup/${subgroupId}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.delete(url, header).pipe(first()).toPromise();
    return resp;
  }

  private complyApi(subgroup: PartSubgroup): any {
    let apiSubgroup = {
      description: subgroup.description,
      salePricePercentage: subgroup.salePricePercentage,
    }
    if(subgroup.group){
      apiSubgroup["group"] = subgroup.group._id;
    }
    return apiSubgroup;
  }


}
